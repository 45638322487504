import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlay,
  faPause,
  faBackward,
  faForward,
} from '@fortawesome/free-solid-svg-icons'

import { changePage, chooseCategory } from '../../../store/actions/page'
import { connect } from 'react-redux'

import videojs from 'video.js'
import 'videojs-contrib-dash'
import 'videojs-contrib-hls'
import '../../../../node_modules/video.js/dist/video-js.css'
import './VideojsPlayer.css'
import axios from '../../../axios/axiosConfig'

import Loader from '../../../components/UI/Loader/Loader'

var progressInterval = null
var getDurationInterval = null
var rewindInterval = null
var idleTimeOut = null
var rewindSpeedInterval = null
var setBackTimeOut = null

var isBack = false

class PlayerFullScreen extends Component {
  state = {
    showControls: true,
    title: null,
    position: 1,
    controlPosition: 1,
    progressPercent: 0,
    progressTime: '0:00:00',
    duration: '0:00:00',
    isPlaying: true,
    streamUrl: null,
    mediaIndex: 0,
    live: false,
    loader: true,
  }

  rewindTime = 0
  backwardCount = 0
  forwardCount = 0
  holdRewind = false
  rewindSpeed = 5
  initPlayerStatus = false

  componentDidMount() {
    document.addEventListener('mousemove', this.mouseControlsHideHandler)

    let findId = this.props.location.pathname.split('/')
    let serieId = null;
    if (!findId[findId.length - 1].includes('watch')) {
      findId = findId[findId.length - 1]
    } else if(findId[findId.length - 1].includes('watch-')){
      serieId = findId[findId.length - 1].split('-').slice(1).join('-')
      findId = findId[findId.length - 2]
    } else findId = findId[findId.length - 2]

    if (this.props.location.pathname.includes('filmovi')) {
      axios.get('/movie/' + findId).then((res) => {
        // console.log(res.data)
        this.setState({ title: res.data.name })
        this.findStreamUrl(res.data.src)
      })
    } else if (this.props.location.pathname.includes('serije')) {
      axios.get('/serie/' + findId).then((res) => {
        const serie = res.data.episodes.filter(item => item.id === parseInt(serieId))[0]
        this.setState({ title: serie.name })
        this.findStreamUrl(serie.src)
        console.log(serie.src)
      })
    }else if(this.props.location.pathname.includes('tv-unazad')){
      axios.get('/channel/' + findId).then((res) => {
        // console.log(serieId)
        // console.log(res.data.epgs[serieId.split("_")[0]])
        const serie = res.data.epgs[serieId.split("_")[0]].filter(item => item.id === parseInt(serieId.split("_")[1]))[0]
        console.log(serie)
        this.setState({ title: serie.name})
        this.findStreamUrl(serie.src)
      })
    } else if (this.props.location.pathname.includes('tv-uzivo')) {
      axios.get('/channel/' + findId).then((res) => {
        this.setState({ title: res.data.name })
        this.findStreamUrl(res.data.src)
      })
    }

    // this.findStreamUrl();

    document.addEventListener('keydown', this.keyHandler)
  }

  componentWillUnmount() {
    // Utility.removeKeyHandler(this.keyHandler);
    clearInterval(progressInterval)
    clearTimeout(idleTimeOut)
    clearInterval(getDurationInterval)
    this.disposePlayer()

    document.removeEventListener('mousemove', this.mouseControlsHideHandler)
    document.removeEventListener('keydown', this.keyHandler)
  }

  findStreamUrl = (src) => {
    this.setStreamUrl(src)
  }

  setStreamUrl = (media) => {
    let url = media
    console.log(url)
    if (media.endsWith('.ts')) {
      url = media.replace('.ts', '.m3u8')
      if(this.props.history.location.pathname.includes('tv-uzivo')){
        this.setState({ live: true })
      }
   
    }

    this.setState(
      {
        streamUrl: url,
      },
      () => {
        if (this.initPlayerStatus) {
          this.player.pause()

          setTimeout(() => {
            this.player.play()
            this.seekTo(0)
            this.setProgress()
            this.getDuration()
          }, 1000)
        } else {
          this.initPlayer()
        }
      },
    )
  }

  getDuration = () => {
    clearInterval(getDurationInterval)

    let durationMiliseconds, duration, currentTime

    getDurationInterval = setInterval(() => {
      durationMiliseconds = this.player.duration() * 1000
      currentTime = this.player.currentTime()

      if (durationMiliseconds > 0 && currentTime > 0) {
        duration = this.milisecondsToTime(durationMiliseconds)

        this.setState({
          duration,
        })

        //this.setProgress();

        clearInterval(getDurationInterval)
      }
    }, 50)
  }

  milisecondsToTime = (allMilliseconds) => {
    // var milliseconds = parseInt((allMilliseconds % 1000) / 100),
    let seconds = parseInt((allMilliseconds / 1000) % 60),
      minutes = parseInt((allMilliseconds / (1000 * 60)) % 60),
      hours = parseInt((allMilliseconds / (1000 * 60 * 60)) % 24)

    //hours = (hours < 10) ? "0" + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes
    seconds = seconds < 10 ? '0' + seconds : seconds

    return hours + ':' + minutes + ':' + seconds
  }

  mouseControlsHideHandler = () => {
    clearTimeout(idleTimeOut)

    this.idleTimeOut()

    if (!this.state.showControls && !isBack) {
      this.setState({
        showControls: true,
      })
    }
  }

  idleTimeOut = () => {
    clearTimeout(idleTimeOut)

    idleTimeOut = setTimeout(() => {
      this.hideControls()
    }, 5000)
  }

  playingHandler = () => {
    this.setState({
      isPlaying: true,
    })
    // this.props.setLoader(false);
    this.setState({ loader: false })
  }

  seekTo = (time) => {
    // this.props.setLoader(true);
    this.setState({ loader: true })
    this.player.pause()
    this.player.currentTime(time)
  }

  pause = () => {
    this.player.pause()
    this.setState({
      isPlaying: false,
    })
  }

  play = () => {
    this.rewindSpeed = 5

    clearInterval(rewindInterval)

    if (this.rewindTime > 0) {
      this.seekTo(this.rewindTime)

      this.setProgress()

      this.rewindTime = 0
    } else {
      this.player.play()
    }
  }

  pause = () => {
    this.player.pause()
    this.setState({
      isPlaying: false,
    })
  }

  togglePlay = () => {
    if (this.player.paused()) {
      this.play()
    } else {
      this.pause()
    }
  }

  showControls = () => {
    this.setState({
      showControls: true,
    })

    this.idleTimeOut()
  }

  hideControls = () => {
    this.setState({
      showControls: false,
      position: 1,
      controlPosition: 1,
    })

    isBack = true

    setBackTimeOut = setTimeout(() => {
      isBack = false
    }, 1000)
  }

  readyHandler = () => {
    // this.props.setLoader(true);

    if (this.props.sceneTime > 0) {
      this.seekTo(this.props.sceneTime)
    } else {
      // if (this.player) {
      //   setTimeout(() => {
          this.player.play()
      //   }, 1000)
      // }
    }

    //this.player.play();
    this.setProgress()
    this.getDuration()
    this.idleTimeOut()
  }

  errorHandler = () => {
    console.log('ERROR PLAYING HANDLER')
  }

  seekedHandler = () => {
    this.player.play()
  }

  endedHandler = () => {

    this.setState({
      isPlaying: false,
    })

  }

  disposePlayer = () => {
    if (this.player) {
      this.player.dispose()
    }
  }

  initPlayer = () => {
    this.player = videojs('video', {
      html5: {
        nativeCaptions: false,
        hls: {
          // withCredentials: false,
        },
      },
      errorDisplay: false,
    })

    this.player.on('ready', this.readyHandler)
    this.player.on('error', this.errorHandler)
    this.player.on('playing', this.playingHandler)
    this.player.on('seeked', this.seekedHandler)
    this.player.on('ended', this.endedHandler)

    this.initPlayerStatus = true
  }

  showControls = () => {
    this.setState({
      showControls: true,
    })

    this.idleTimeOut()
  }

  hideControls = () => {
    this.setState({
      showControls: false,
      position: 1,
      controlPosition: 1,
    })

    isBack = true

    setBackTimeOut = setTimeout(() => {
      isBack = false
    }, 1000)
  }

  setProgress = () => {
    clearInterval(progressInterval)

    progressInterval = setInterval(() => {
      let duration = this.player.duration() // in seconds
      let progress = this.player.currentTime() // in seconds

      let progressPercent = (100 * progress) / duration
      let progressTime = this.milisecondsToTime(progress * 1000)

      this.setState({
        progressPercent,
        progressTime,
      })
    }, 1000)
  }

  // Set rewind buffer
  setRewindBuffer = (stream_duration, stream_current_time) => {
    let progressPercent = 0
    let progressTime

    if (stream_current_time >= stream_duration || !stream_current_time) {
      progressPercent = 100
      progressTime = this.milisecondsToTime(stream_duration * 1000) //Change method
      this.rewindTime = stream_duration
      clearInterval(rewindInterval)
    } else {
      progressPercent = (100 * stream_current_time) / stream_duration
      progressTime = this.milisecondsToTime(stream_current_time * 1000) //Change method
      if (stream_current_time > 0) {
        this.rewindTime = stream_current_time
      } else {
        this.rewindTime = 0
      }
    }

    this.setState({
      progressPercent,
      progressTime,
    })
  }

  mouseOverControls = (position,controlPosition) => {
    this.setState({
      controlPosition,
      position: position,
    })
  }

  mouseDownBackward = () => {
    this.backward(true)
  }

  mouseDownForward = () => {
    this.forward(true)
  }

  keyUpHandler = (e) => {
    //this.props.setLoader(true);

    this.holdRewind = false
    this.rewindSpeed = 5

    clearInterval(rewindInterval)
    clearInterval(rewindSpeedInterval)
    this.play()
    this.idleTimeOut()

    document.removeEventListener('keyup', this.keyUpHandler)
  }

  mouseUpBackwardForward = () => {
    // clearInterval(rewindInterval);
    // this.idleTimeOut();
    // document.removeEventListener('keyup', this.keyUpHandler);

    this.keyUpHandler()
  }

  setRewindSpeed = () => {
    clearInterval(rewindSpeedInterval)

    rewindSpeedInterval = setInterval(() => {
      if (this.rewindSpeed < 640) {
        this.rewindSpeed = this.rewindSpeed * 2
      } else {
        clearInterval(rewindSpeedInterval)
      }
    }, 1200)
  }

  left = () => {
    if (this.state.position === 0) {
      this.backward(true)
    } else if (this.state.position === 1) {
      if (this.state.controlPosition > 0) {
        this.setState({
          controlPosition: this.state.controlPosition - 1,
        })
      }
    }
  }

  right = () => {
    if (this.state.position === 0) {
      this.forward(true)
    } else if (this.state.position === 1) {
      if (this.state.controlPosition < 2) {
        this.setState({
          controlPosition: this.state.controlPosition + 1,
        })
      }
    }
  }

  up = () => {
    if (this.state.position === 1) {
      this.setState({
        position: 0,
      })
    }
  }

  down = () => {
    if (this.state.position === 0) {
      this.setState({
        position: 1,
      })
    }
  }

  enter = () => {
    if (this.state.position === 0) {
      this.togglePlay()
    } else if (this.state.position === 1) {
      switch (this.state.controlPosition) {
        case 0:
          this.backward(true)
          break

        case 1:
          this.togglePlay()
          break

        case 2:
          this.forward(true)
          break

        default:
      }
    }
  }

  backward = (isHold) => {
    if (!this.holdRewind) {
      clearInterval(progressInterval)
      clearInterval(rewindInterval)
      clearTimeout(idleTimeOut)

      if (isHold) {
        this.holdRewind = true
        this.setRewindSpeed()
      }

      this.pause()

      this.forwardCount = 0

      let stream_duration = this.player.duration()

      let current_time
      let interval

      if (this.backwardCount < 5) {
        this.backwardCount++
      }

      if (isHold) {
        interval = 50
        this.backwardCount = 0
      } else {
        interval = 200 / this.backwardCount
        this.rewindSpeed = this.backwardCount * this.rewindSpeed
      }

      rewindInterval = setInterval(() => {
        if (this.rewindTime > 0) {
          current_time = this.rewindTime
        } else {
          current_time = this.player.currentTime()
        }

        // let time_back;

        // if ( isHold ) {
        //   time_back = 10;
        // } else {
        //this.rewindSpeed = this.backwardCount * 2 * this.rewindSpeed;
        // }

        let time = current_time - this.rewindSpeed

        if (time < 0) {
          time = 0
        }

        this.setRewindBuffer(stream_duration, time)
      }, interval)

      if (isHold) {
        document.addEventListener('keyup', this.keyUpHandler)
      }
    }
  }

  forward = (isHold) => {
    if (!this.holdRewind) {
      clearInterval(progressInterval)
      clearInterval(rewindInterval)
      clearTimeout(idleTimeOut)

      if (isHold) {
        this.holdRewind = true
        this.setRewindSpeed()
      }

      this.pause()

      this.backwardCount = 0

      let stream_duration = this.player.duration()

      let current_time
      let interval

      if (this.forwardCount < 5) {
        this.forwardCount++
      }

      if (isHold) {
        interval = 50
        this.forwardCount = 0
      } else {
        interval = 200 / this.forwardCount
        this.rewindSpeed = this.forwardCount * this.rewindSpeed
      }

      rewindInterval = setInterval(() => {
        if (this.rewindTime > 0) {
          current_time = this.rewindTime
        } else {
          current_time = this.player.currentTime()
        }

        // let time_forward;

        // if (isHold) {
        //   time_forward = 10;
        // } else {
        //this.rewindSpeed = this.forwardCount * 2 * this.rewindSpeed;
        // }

        let time = current_time + this.rewindSpeed

        this.setRewindBuffer(stream_duration, time)
      }, interval)

      if (isHold) {
        document.addEventListener('keyup', this.keyUpHandler)
      }
    }
  }

  // back = () => {
  //   this.props.chooseCategory(
  //     this.props.page.pageInfo.back.title,
  //     this.props.page.pageInfo.back.link,
  //     this.props.page.pageInfo.back.back
  //   );
  //   if(this.state.live){
  //     this.props.changePage(4);
  //   }else {
  //     this.props.changePage(5);
  //   }
  // }

  keyHandler = (event) => {
    if (this.state.showControls) {
      this.idleTimeOut()

      switch (event.key) {
        case 'ArrowLeft':
          if(!this.state.live){
          this.left()
          }
          break
        case 'ArrowRight':
          if(!this.state.live){
          this.right()
          }
          break
        case 'ArrowUp':
          if(!this.state.live){
          this.up()
          }
          break
        case 'ArrowDown':
          if(!this.state.live){
          this.down()
          }
          break
        case 'Enter':
          if (this.state.showControls) {
            this.enter()
          } else {
            this.showControls(true)
          }
          break
        default:
          break
      }
    } else {
      if (event.keyCode === 'Backspace' || event.keyCode === 'Escape') {
        clearTimeout(setBackTimeOut)
        // this.closePlayerFullScreen();
      } else {
        this.showControls()
      }
    }
  }

  render() {
    let url = this.state.streamUrl

    let progressStyle = {
      width: `${this.state.progressPercent}%`,
    }

    return (
      <div className="player_full_screen">
        <div id="video_holder">
          <video id="video" preload="none">
            <source src={url} />
          </video>
        </div>

        {this.state.loader ? (
          <div className="loader_player">
            <Loader />
          </div>
        ) : (
          <div
            className={
              'controls_holder' + (this.state.showControls ? ' show' : '')
            }
          >
            <div className="stream_title_holder">
              {/* {this.props.page.pageInfo.title} */}
              {this.state.title}
            </div>

            {this.state.live ? null : (
              <div className="time_progress_holder">
                <div className="time time_start">{this.state.progressTime}</div>
                <div
                  className={
                    'progress_holder' +
                    (this.state.position === 0 ? ' active' : '')
                  }
                  onMouseOver={() => this.mouseOverControls(0,0)}
                >
                  <div className="progress" style={progressStyle}></div>
                </div>
                <div className="time time_end">{this.state.duration}</div>
              </div>
            )}

            <div className="main_controls">
              {!this.state.live ? 
              <div
                className={
                  'control backward' +
                  (this.state.position === 1 && this.state.controlPosition === 0
                    ? ' active'
                    : '')
                }
                onMouseOver={() => this.mouseOverControls(1,0)}
                onMouseDown={() => this.mouseDownBackward()}
                onMouseUp={() => this.mouseUpBackwardForward()}
              >
                <FontAwesomeIcon icon={faBackward} />
              </div>
              : null}
            
              <div
                className={
                  'control play_pause' +
                  (this.state.position === 1 && this.state.controlPosition === 1
                    ? ' active'
                    : '')
                }
                onMouseOver={() => this.mouseOverControls(1,1)}
                onClick={() => this.togglePlay()}
              >
                {this.state.isPlaying ? (
                  <FontAwesomeIcon icon={faPause} />
                ) : (
                  <FontAwesomeIcon icon={faPlay} />
                )}
              </div>
              {!this.state.live ? 
              <div
                className={
                  'control forward' +
                  (this.state.position === 1 && this.state.controlPosition === 2
                    ? ' active'
                    : '')
                }
                onMouseOver={() => this.mouseOverControls(1,2)}
                onMouseDown={() => this.mouseDownForward()}
                onMouseUp={() => this.mouseUpBackwardForward()}
              >
                <FontAwesomeIcon icon={faForward} />
              </div>
              : null}
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePage: (page) => dispatch(changePage(page)),
    // savePages: (pageList) => dispatch(savePages(pageList)),
    chooseCategory: (title, link, back) =>
      dispatch(chooseCategory(title, link, back)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerFullScreen)
