import React, { useEffect, useState } from 'react'
import './Nalog.css'

import PageLayout from '../../../hoc/PageLayout/PageLayout'
import NalogChoice from '../../../components/Choice/NalogChoice/NalogChoice'
import Logout from '../../../components/Logout/Logout'

import axios from '../../../axios/axiosConfig'

import Loader from '../../../components/UI/Loader/Loader'
import {savePages} from '../../../store/actions/page'

import {connect} from 'react-redux'

const Nalog = (props) => {
  const [mover, setMover] = useState({ row: 1, column: 1 })
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const moverChange = (e) => {
    if (e.key === 'ArrowRight') {
      if (mover.row === 2) {
        if (mover.column === 1) {
          setMover({row:2,column:2})
        } else setMover({row:2,column:1})
      }
    } else if (e.key === 'ArrowLeft') {
      if (mover.row === 2) {
        if (mover.column === 2) {
          setMover({row:2,column:1})
        } else setMover({row:2,column:2})
      }
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown"){
      if(mover.row === 2){
        setMover({row:1,column:mover.column})
      }else setMover({row:2,column:mover.column})
    } else if (e.key === "Enter"){
      if(mover.row === 1){
       logout()
      }
    }
  }

  const logout = () => {
    localStorage.clear()
    props.history.push('/login')
    props.savePages([])
  }

  const hoverSet = (property) => {
    setMover(property)
  }

  useEffect(() => {
    axios
      .get('/user')
      .then((res) => {
        setUser(res.data.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', moverChange)
    return () => {
      window.removeEventListener('keydown', moverChange)
    }
  })

  return (
    <PageLayout title="Nalog" back={1}>
      <div className="OptionsNalog">
        <Logout
          active={1 === mover.row}
          onHover={hoverSet.bind(this, { row: 1, column: mover.column })}
          onClick={logout}
        />
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <NalogChoice
              text={[
                'tekst koji mi mozemo da menjamo tipa email za kontakt ili brojevi telefona',
              ]}
              active={2 === mover.row && 1 === mover.column}
              onHover={hoverSet.bind(this, { row: 2, column: 1 })}
            />
            <NalogChoice
              text={[
                `Korisnicko ime: ${user.username}`,
                `Datum isticanja: ${user.exp_date}`,
              ]}
              active={2 === mover.row && 2 === mover.column}
              onHover={hoverSet.bind(this, { row: 2, column: 2 })}
            />
          </React.Fragment>
        )}
      </div>
    </PageLayout>
  )
}

const mapDispatchToProps = {savePages}

export default connect(null,mapDispatchToProps)(Nalog)
