import React, { useState, useEffect } from "react";
import "./Log-in.css";

import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";

import Logo from "../../../assets/images/logo.png";

import { connect } from "react-redux";
import { changePage } from "../../../store/actions/page";

import axios from "../../../axios/axiosConfig";

const Login = (props) => {
  const [mover, setMover] = useState(1);
  const [user, setUser] = useState({ username: "", password: "" });
  const [error,setError] = useState(null);

  const moverChange = (e) => {
    const current = mover;
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        if (current < 3) {
          setMover(current + 1);
        } else if (current === 3) {
          setMover(1);
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        if (current > 1) {
          setMover(current - 1);
        } else if (current === 1) {
          setMover(3);
        }
        break;
      case "Enter":
          logIn();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", moverChange);
    return () => {
      window.removeEventListener("keydown", moverChange);
    };
  });

  const logIn = () => {
    axios
      .post("/login", { username: user.username, password: user.password })
      .then((response) => {
        localStorage.setItem("jwtToken",'Basic ' + Buffer.from(user.username + ":" + user.password ).toString('base64'));
        props.history.push("/");
      })
      .catch((error) => setError(error.response.data.error));
  };

  const setUsername = (value) => {
    setUser({ ...user, username: value });
  };

  const setPassword = (value) => {
    setUser({ ...user, password: value });
  };

  const hoverSet = (property) => {
    setMover(property);
  };

  return (
    <div className="Login">
      <div className="LoginIn">
        <img src={Logo} alt="logo" />
        <Input
          type="text"
          placeholder="Vas email"
          active={1 === mover}
          onChange={setUsername}
          onMouseOver={hoverSet.bind(this, 1)}
          value="unclemike"
        />
        <Input
          type="password"
          placeholder="Vasa lozinka"
          active={2 === mover}
          onChange={setPassword}
          onMouseOver={hoverSet.bind(this, 2)}
          value="unclemike"
        />
        <Button
          text="Uloguj se"
          click={logIn}
          active={3 === mover}
          onMouseOver={hoverSet.bind(this, 3)}
        />
          <p className="errorMessage">{error}</p>
        <p className="registracija">
          Registraciju na EXYU.tv servis mozete izvrsiti na
          www.exyu.tv/registracija
        </p>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePage: (page) => dispatch(changePage(page)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
