import React from "react";
import "./Logout.css";

const logout = (props) => {
  const classCurr = ["ContainerLogout"];

  if (props.active) {
    classCurr.push(["active"]);
  }

return(
  <div className={classCurr.join(' ')} onMouseOver={props.onHover} onClick={props.onClick}>
    <div className={"Logout"}>
        <p>Izloguj se</p>
    </div>
  </div>
)
}

export default React.memo(logout);
