import React, { useState, useEffect, useCallback } from "react";
import "./Home.css";

import Logo from "../../../assets/images/logo.png";
import Option from "../../../components/Option/Option";

import { connect } from "react-redux";
import { savePages } from "../../../store/actions/page";

const Home = (props) => {
  const [mover, setMover] = useState(
    props.page.lastpages[0] ? props.page.lastpages[0].mover : 1
  );
  const [hoveredPage, setHoveredPage] = useState(null);

  useEffect(() => {
    if(!localStorage.getItem("jwtToken")){
      props.history.push("/login")
    }
  },[])

  const clicked = useCallback(() => {
    props.savePages([{ page: "home", mover }]);
    switch (mover) {
      case 1:
        // props.chooseCategory("TV Uzivo", "/categories?type=live", 1);
        props.history.push("/tv-uzivo");
        break;
      case 2:
        // props.chooseCategory("TV Unazad", "/categories?type=live", 1);
        props.history.push("/tv-unazad");
        break;
      case 3:
        props.history.push("/videoteka");
        break;
      case 4:
        // props.chooseCategory("Radio", "/radios", 1);
        props.history.push("/radio");
        break;
      case 5:
        props.history.push("/nalog");
        break;
      default:
        break;
    }
  }, [hoveredPage, mover, props]);

  useEffect(() => {
    const moverChange = (e) => {
      const current = mover;
      if (e.key === "ArrowRight") {
        if (current < 5) {
          setMover(current + 1);
        } else if (current === 5) {
          setMover(1);
        }
      } else if (e.key === "ArrowLeft") {
        if (current > 1) {
          setMover(current - 1);
        } else if (current === 1) {
          setMover(5);
        }
      }
      if (e.key === "Enter") {
        clicked();
      }
    };

    window.addEventListener("keydown", moverChange);
    return () => {
      window.removeEventListener("keydown", moverChange);
    };
  }, [clicked, mover]);

  const hoverSet = (property) => {
    setMover(property);
  };

  let idAct = null;

  const getLink = (id) => {
    idAct = id;
  };

  useEffect(() => {
    setHoveredPage(idAct);
  }, [idAct, mover]);

  return (
    <div className={"Home"}>
      <img src={Logo} alt="logo" />
      <div className={"OptionsHome"}>
        <Option
          id={4}
          text="TV Uzivo"
          active={1 === mover}
          hovered={getLink}
          onHover={hoverSet.bind(this, 1)}
          onClick={clicked}
        />
        <Option
          id={4}
          text="TV Unazad"
          active={2 === mover}
          hovered={getLink}
          onHover={hoverSet.bind(this, 2)}
          onClick={clicked}
        />
        <Option
          id={2}
          text="Videoteka"
          active={3 === mover}
          hovered={getLink}
          onHover={hoverSet.bind(this, 3)}
          onClick={clicked}
        />
        <Option
          id={4}
          text="Radio"
          active={4 === mover}
          hovered={getLink}
          onHover={hoverSet.bind(this, 4)}
          onClick={clicked}
        />
        <Option
          id={3}
          text="Nalog"
          active={5 === mover}
          hovered={getLink}
          onHover={hoverSet.bind(this, 5)}
          onClick={clicked}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { savePages })(Home);
