import React, { useState, useEffect } from 'react'
import './Kategorija.css'

import { connect } from 'react-redux'
import {
  changePage,
  savePages,
  chooseCategory,
} from '../../../store/actions/page'
import axios from '../../../axios/axiosConfig'

import PageLayout from '../../../hoc/PageLayout/PageLayout'
import List from '../../../components/List/List'
import Loader from '../../../components/UI/Loader/Loader'

const Kategorija = (props) => {
  // const [prevPage, setPrevPage] = useState(props.page.pageInfo);
  const [link, setLink] = useState(null)
  const [title, setTitle] = useState(null)

  const [mover, setMover] = useState({ row: 1, column: 1 })
  const [loading, setLoading] = useState(true)
  const [hoveredPage, setHoveredPage] = useState(null)
  const [categories, setCategories] = useState([])
  const [slice, setSlice] = useState({ from: 0, lastRow: 4 })

  useEffect(() => {
    // console.log(props.location.pathname)
    if (props.location.pathname === '/videoteka/filmovi') {
      setLink('/categories?type=movie')
      setTitle('Filmovi')
    } else if (props.location.pathname === '/videoteka/serije') {
      setLink('/categories?type=series')
      setTitle('Serije')
    } else if (props.location.pathname === '/tv-uzivo') {
      setLink('/categories?type=live')
      setTitle('TV Uzivo')
    } else if (props.location.pathname === '/tv-unazad') {
      setLink('/categories?type=live')
      setTitle('TV Unazad')
    } else if (props.location.pathname === '/radio') {
      setLink('/radios')
      setTitle('Radio')
    } else if (props.location.pathname === "/tv-uzivo/favoriti" || props.location.pathname === "/tv-unazad/favoriti"){
      setLink('/favorites?favorite_type=channel')
      setTitle('Favoriti Kanali')
    }else if (props.location.pathname === '/videoteka/filmovi/favoriti') {
      setLink('/favorites?favorite_type=movie')
      setTitle('Favoriti Filmovi')
    }else if (props.location.pathname === "/videoteka/serije/favoriti") {
      setLink('/favorites?favorite_type=serie')
      setTitle('Favoriti Serije')
    }else if(props.location.pathname === "/videoteka/serije/favoriti") {
      setLink('/favorites?favorite_type=radio')
      setTitle('Favoriti Radio')
    }else if (
      props.location.pathname.includes('/tv-uzivo/') ||
      props.location.pathname.includes('/tv-unazad/')
    ) {
      let arr = props.location.pathname.split('/')
      arr = arr[arr.length - 1]
      setLink('/channels?category=' + arr)
      setTitle(props.location.state)
    } else if (props.location.pathname.includes('/serije/')) {
      let arr = props.location.pathname.split('/')
      arr = arr[arr.length - 1]
      setLink('/series?category=' + arr)
      setTitle(props.location.state)
    } else if (props.location.pathname.includes('/filmovi/')) {
      let arr = props.location.pathname.split('/')
      arr = arr[arr.length - 1]
      setLink('/movies?category=' + arr)
      setTitle(props.location.state)
    }
  }, [props])

  const clicked = () => {
    if(hoveredPage.id === "0"){
      props.history.push(
        props.location.pathname + '/favoriti',
        hoveredPage.title,
      )
    }else {
      props.history.push(
        props.location.pathname + '/' + hoveredPage.id,
        hoveredPage.title,
      )
    }
 
  }

  // const clicked = useCallback(() => {
  //   if (prevPage.title === "TV Uzivo" || prevPage.title === "TV Unazad") {
  //     props.savePages([
  //       ...props.page.lastpages.slice(0, 2),
  //       { page: prevPage.title, mover, slice },
  //     ]);
  //     setPrevPage({
  //       title: prevPage.title + " / " + hoveredPage.title,
  //       link: "/channels?category=" + hoveredPage.id,
  //       back: prevPage,
  //     });

  //     props.history.push(props.history.location.pathname + "/" + hoveredPage.title)
  //   } else if (prevPage.title === "Videoteka / Serije") {
  //     props.savePages([
  //       ...props.page.lastpages.slice(0, 2),
  //       { page: prevPage.title, mover, slice },
  //     ]);
  //     setPrevPage({
  //       title: prevPage.title + " / " + hoveredPage.title,
  //       link: "/series?category=" + hoveredPage.id,
  //       back: prevPage,
  //     });
  //     props.history.push(props.history.location.pathname + "/" + hoveredPage.title)
  //   } else if (prevPage.title === "Videoteka / Filmovi") {
  //     props.savePages([
  //       ...props.page.lastpages.slice(0, 2),
  //       { page: prevPage.title, mover, slice },
  //     ]);
  //     setPrevPage({
  //       title: prevPage.title + " / " + hoveredPage.title,
  //       link: "/movies?category=" + hoveredPage.id,
  //       back: prevPage,
  //     });
  //     props.history.push(props.history.location.pathname + "/" + hoveredPage.title)
  //   } else if (prevPage.title.includes("TV Unazad /")) {
  //     props.savePages([
  //       ...props.page.lastpages.slice(0, 3),
  //       { page: prevPage.title, mover, slice },
  //     ]);
  //     props.chooseCategory(hoveredPage.title, "/channel/" + hoveredPage.id, {
  //       back: 4,
  //       title: prevPage.title,
  //       link: prevPage.link,
  //     });

  //     // props.changePage(7)
  //   } else if (prevPage.title.includes("Filmovi /")) {
  //     props.savePages([
  //       ...props.page.lastpages.slice(0, 3),
  //       { page: prevPage.title, mover, slice },
  //     ]);
  //     props.chooseCategory(hoveredPage.title, "/movie/" + hoveredPage.id, {
  //       back: 4,
  //       title: prevPage.title,
  //       link: prevPage.link,
  //     });
  //     props.history.push(props.history.location.pathname + "/" + hoveredPage.title)
  //     props.changePage(5);
  //   } else if (prevPage.title.includes("Serije /")) {
  //     props.savePages([
  //       ...props.page.lastpages.slice(0, 3),
  //       { page: prevPage.title, mover, slice },
  //     ]);
  //     props.chooseCategory(hoveredPage.title, "/serie/" + hoveredPage.id, {
  //       back: 4,
  //       title: prevPage.title,
  //       link: prevPage.link,
  //     });
  //     props.changePage(5);
  //   } else if (prevPage.title.includes("TV Uzivo /")) {
  //     props.savePages([
  //       ...props.page.lastpages.slice(0, 3),
  //       { page: prevPage.title, mover, slice },
  //     ]);
  //     props.chooseCategory(hoveredPage.title, hoveredPage.src, {
  //       back: prevPage.back,
  //       title: prevPage.title,
  //       link: prevPage.link,
  //     });
  //     props.changePage(6);
  //   // } else if (hoveredPage.src && hoveredPage.src.includes("duration")) {
  //   //   props.savePages([
  //   //     ...props.page.lastpages.slice(0, 4),
  //   //     { page: prevPage.title, mover, slice },
  //   //   ]);
  //   //   props.chooseCategory(hoveredPage.title, hoveredPage.src, {
  //   //     back: prevPage.back,
  //   //     title: prevPage.title,
  //   //     link: prevPage.link,
  //   //   });
  //   //   props.changePage(6);
  //   } else if (prevPage.title === "Radio") {
  //     // setPrevPage({
  //     //   title: prevPage.title + " / Kategorija",
  //     //   link: null,
  //     //   back: prevPage,
  //     // });
  //   }
  // }, [hoveredPage, prevPage, props, mover, slice]);

  useEffect(() => {
    const column = categories.length < 4 ? categories.length : 4
    const row = categories.length < 12 ? Math.ceil(categories.length / 4) : 3

    const moverChange = (e) => {
      if (!loading) {
        const current = { ...mover }
        switch (e.key) {
          case 'ArrowDown':
            if (current.row < row) {
              if (current.row + 1 === row && current.column > slice.lastRow) {
                setMover({ row: current.row + 1, column: slice.lastRow })
              } else {
                setMover({ ...current, row: current.row + 1 })
              }
            } else if (current.row === row) {
              const currSlice = slice
              if (currSlice.from + 16 <= categories.length) {
                setSlice({
                  from: currSlice.from + 4,
                  lastRow: currSlice.lastRow,
                })
              } else if (currSlice.from + 12 < categories.length) {
                setSlice({
                  from: currSlice.from + 4,
                  lastRow: categories.length - (currSlice.from + 12),
                })
                if (mover.column > categories.length - (currSlice.from + 12)) {
                  setMover({
                    ...current,
                    column: categories.length - (currSlice.from + 12),
                  })
                }
              }
            }
            if (current.row === row - 1) {
              if (slice.from + 8 + slice.lastRow === categories.length) {
                if (mover.column > slice.lastRow) {
                  setMover({ row: row, column: slice.lastRow })
                }
              }
            }
            break
          case 'ArrowUp':
            if (current.row > 1) {
              setMover({ ...current, row: current.row - 1 })
            } else if (current.row === 1) {
              const currSlice = slice
              if (currSlice.from - 4 >= 0) {
                if (currSlice.lastRow < 4) {
                  currSlice.lastRow = 4
                }
                setSlice({
                  from: currSlice.from - 4,
                  lastRow: currSlice.lastRow,
                })
              }
            }
            break
          case 'ArrowRight':
            if (current.row === row) {
              if (current.column < slice.lastRow) {
                setMover({ ...current, column: current.column + 1 })
              } else if (current.column === slice.lastRow) {
                setMover({ ...current, column: 1 })
              }
            } else if (current.column === column) {
              setMover({ ...current, column: 1 })
            } else if (current.column < column) {
              setMover({ ...current, column: current.column + 1 })
            }
            break
          case 'ArrowLeft':
            if (current.column > 1) {
              setMover({ ...current, column: current.column - 1 })
            } else if (current.row === row && current.column === 1) {
              setMover({ ...current, column: slice.lastRow })
            } else if (current.column === 1) {
              setMover({ ...current, column: column })
            }
            break
          case 'Enter':
            clicked()
            break
          default:
            break
        }
      }
    }

    window.addEventListener('keydown', moverChange)
    return () => {
      window.removeEventListener('keydown', moverChange)
    }
  }, [categories.length, clicked, mover, slice , loading])

  useEffect(() => {
    const scrollDown = (e) => {
      const current = slice
      if (e.deltaY > 0) {
        if (current.from + 16 <= categories.length) {
          setSlice({ from: current.from + 4, lastRow: current.lastRow })
        } else if (current.from + 12 < categories.length) {
          setSlice({
            from: current.from + 4,
            lastRow: categories.length - (current.from + 12),
          })
        }
      } else if (e.deltaY < 0 && current.from - 4 >= 0) {
        setSlice({ from: current.from - 4, lastRow: 4 })
      }
    }

    window.addEventListener('wheel', scrollDown)
    return () => {
      window.removeEventListener('wheel', scrollDown)
    }
  }, [categories.length, slice])

  // useEffect(() => {
  //   setPrevPage(props.page.pageInfo);
  // }, [props.page.pageInfo]);

  // useEffect(() => {
  //   let changed = false;
  //   props.page.lastpages.map((page) => {
  //     if (page.page === prevPage.title) {
  //       setMover(page.mover);
  //       setSlice(page.slice);
  //       return (changed = true);
  //     }
  //     return false;
  //   });
  //   if (!changed) {
  //     setMover({ row: 1, column: 1 });
  //     setSlice({ from: 0, lastRow: 4 });
  //   }
  // }, [prevPage, props.page.lastpages]);

  useEffect(() => {
    setLoading(true)
    if (link) {
      axios
        .get(link)
        .then((response) => {
          setMover({row:1,column:1})
          const categories = response.data

          // console.log(categories)

          if (props.location.pathname === '/tv-uzivo' || props.location.pathname === "/tv-unazad") {
            categories.unshift({ id: "0", name: 'FAVORITI' , order:0 ,type:'live' })
          } else if (props.location.pathname === '/videoteka/filmovi') {
            categories.unshift({ id: "0", name: 'Favoriti' })
          }else if(props.location.pathname === '/videoteka/serije') {
            categories.unshift({ id: "0", name: 'Favoriti' })
          }

          if (categories.data) {
            setCategories(categories.data)
            if (categories.data.length < 12) {
              setSlice({
                from: 0,
                lastRow:
                  categories.data.length % 4 === 0
                    ? 4
                    : categories.data.length % 4,
              })
            }
          } else {
            setCategories(categories)
            if (categories.length < 12) {
              setSlice({
                from: 0,
                lastRow:
                  categories.length % 4 === 0 ? 4 : categories.length % 4,
              })
            }
          }
          setLoading(false)
        })
        .catch((error) => console.log(error))
    }
  }, [link])

  useEffect(() => {
    if (categories.length > 0) {
      let cat = categories[slice.from + (mover.row - 1) * 4 + mover.column - 1]
      if (cat) {
        setHoveredPage({
          id: cat.id ? cat.id : cat,
          title: cat.name,
          src: cat.src,
          favorite:cat.favorite
        })
      }
    }
  }, [categories, mover, slice])

  const hoverSet = (property) => {
    const row = Math.floor(property / 4 + 1)
    const column = Math.floor((property % 4) + 1)
    setMover({ row, column })
  }

  const toggleFavorite = () => {
    let type = null;
    if(props.location.pathname.includes("/videoteka/filmovi/")){
      type = "movie"
    }else if(props.location.pathname.includes("/videoteka/serije/")){
      type = "serie"
    }else if(props.location.pathname.includes("/tv-uzivo") || props.location.pathname.includes("/tv-unazad")){
      type = "channel"
    }else if(props.location.pathname.includes("/radio")){
      type = "radio"
    }

    let route = "/favorite/add"

    if(hoveredPage.favorite){
      route = "/favorite/remove"
    }

    axios.post(route,{favorite_type:type,favorite_id:hoveredPage.id})
    .then(res => {
      console.log(res.data)
      setCategories(categories.filter(cat => {
        if(cat.id === res.data){
          cat.favorite = !cat.favorite
        }return cat
      }))
    })
    .catch(err => console.log(err))
  }

  let lista = null

  if (categories) {
    lista = categories
      .slice(slice.from, 8 + slice.from + slice.lastRow)
      .map((category, i) => {
        return (
          <List
            key={i}
            id={category.id}
            text={category.name ? category.name : category}
            icon={category.stream_icon}
            src={category.src ? category.src : null}
            active={i + 1 === (mover.row - 1) * 4 + mover.column}
            onHover={hoverSet.bind(this, i)}
            onClick={clicked}
            favorite={category.favorite}
            toggleFavorite={toggleFavorite}
          />
        )
      })
  }

  return (
    <PageLayout title={title} back={null}>
      <div className="ContainerKategorija">{loading ? <Loader /> : lista}</div>
    </PageLayout>
  )
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = { changePage, savePages, chooseCategory }

export default connect(mapStateToProps, mapDispatchToProps)(Kategorija)
