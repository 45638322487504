import React from "react";
import "./App.css";

// import { connect } from "react-redux";

import Login from "./containers/Layout/Log-in/Log-in";
import Home from "./containers/Layout/Home/Home";
import Videoteka from "./containers/Layout/Videoteka/Videoteka";
import Nalog from "./containers/Layout/Nalog/Nalog";
import Player from "./containers/Layout/Player/VideojsPlayer";

import Kategorija from "./containers/Layout/Kategorija/Kategorija";

import TVUnazad from "./containers/Layout/TVUnazad/TVUnazad";
import Prikaz from "./containers/Layout/Prikaz/Prikaz";

import {BrowserRouter, Switch , Route} from 'react-router-dom'

const App = () => {
 
  return <div className="App">
    <BrowserRouter>
      <Switch>

        <Route path="/login" component={Login} />

        <Route path="/" exact component={Home}/>
        <Route path="/videoteka" exact component={Videoteka} />
        <Route path="/player" component={Player} />
        <Route path="/nalog" exact component={Nalog} />

        <Route path={"/videoteka/:filmserija/:kategorija/:id/:watch"} component={Player} />
        <Route path={"/tv-uzivo/:kategorija/:id"} component={Player} />
        <Route path={"/tv-unazad/:kategorija/:id/:epizoda"} component={Player} />

        <Route path="/videoteka/:filmserija/:kategorija/:id" component={Prikaz}/>
        <Route path="/tv-unazad/:kategorija/:id" component={TVUnazad}/>
        <Route path="/" component={Kategorija} />

      </Switch>
    </BrowserRouter>
    </div>;
};


export default App;
