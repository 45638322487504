import React from "react";
import "./Play.css";

const Play = (props) => {
  const classCurr = ["ContainerPlay"];

  if (props.active) {
    classCurr.push(["active"]);
  }

  return (
    <div
      className={classCurr.join(" ")}
      onMouseOver={props.onHover}
      onClick={props.onClick}
    >
      <div className={"Play"}>
        <p>Play</p>
      </div>
    </div>
  );
};

export default Play;
