import React, { useEffect, useState } from "react";
import "./Videoteka.css";

import PageLayout from "../../../hoc/PageLayout/PageLayout";
import Choice from "../../../components/Choice/Choice";

import { connect } from "react-redux";
import { changePage, savePages , chooseCategory} from "../../../store/actions/page";

const Videoteka = (props) => {
  const [mover, setMover] = useState(
    props.page.lastpages[1]
      ? props.page.lastpages[1].page === "videoteka"
        ? props.page.lastpages[1].mover
        : 1
      : 1
  );

  const moverChange = (e) => {
    const current = mover;
    switch (e.key) {
      case "ArrowRight":
        if (current === 1) {
          setMover(2);
        } else setMover(1);
        break;
      case "ArrowLeft":
        if (current === 2) {
          setMover(1);
        } else setMover(2);
        break;
      case "Enter":
        clicked();
        break;
      default:
        break;
    }
  };

  const hoverSet = (property) => {
    setMover(property);
  };

  const clicked = () => {
    props.savePages([props.page.lastpages[0], { page: "videoteka", mover }]);
    switch (mover) {
      case 1:
        props.chooseCategory("Videoteka / Filmovi", "/categories?type=movie",2);
        props.history.push(props.history.location.pathname + "/filmovi")
        break;
      case 2:
        props.chooseCategory("Videoteka / Serije", "/categories?type=series",2);
        props.history.push(props.history.location.pathname + "/serije")
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", moverChange);
    return () => {
      window.removeEventListener("keydown", moverChange);
    };
  });

  return (
    <PageLayout title="Videoteka" back={1}>
      <div className={"Options"}>
        <Choice
          text="FILMOVI"
          active={1 === mover}
          onHover={hoverSet.bind(this, 1)}
          onClick={clicked}
        />
        <Choice
          text="SERIJE"
          active={2 === mover}
          onHover={hoverSet.bind(this, 2)}
          onClick={clicked}
        />
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePage: (page) => dispatch(changePage(page)),
    savePages: (pageList) => dispatch(savePages(pageList)),
    chooseCategory: (title, link,back) => dispatch(chooseCategory(title, link, back))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Videoteka);
