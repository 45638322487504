import React from "react";
import "./Sezone.css";

const Sezone = (props) => {
  const classCurr = ["ContainerSezone"];

  if (props.active) {
    classCurr.push(["active"]);
    if(props.epizoda || props.serie){
      props.hovered(props.src)
    }
    
  }

  if(props.epizoda) {
    classCurr.push(["epizoda"])
  }

  return (
    <div
      className={classCurr.join(" ")}
      onMouseOver={props.onHover}
      onClick={props.onClick}
    >
      <div className={"Sezone"}>
        <p>{props.name}</p>
      </div>
    </div>
  );
};

export default React.memo(Sezone);
