import React, { useState, useEffect } from "react";
import "./TVUnazad.css";

import axios from "../../../axios/axiosConfig";
import PageLayout from "../../../hoc/PageLayout/PageLayout";
import Loader from "../../../components/UI/Loader/Loader";
import Sezone from "../../../components/Sezone/Sezone";

import { connect } from "react-redux";
import {
  changePage,
  savePages,
  chooseCategory,
} from "../../../store/actions/page";

const TVUnazad = (props) => {
  const [prevPage, setPrevPage] = useState(props.page.pageInfo);
  const [loading, setLoading] = useState(true);

  const [dates, setDates] = useState([]);
  const [title,setTitle] = useState(null)

  const [hoveredEpisode, setHoveredSerie] = useState(null);

  const [moverDate, setMoverDate] = useState(1);
  const [moverSerie, setMoverSerie] = useState(0);

  const [sliceDate, setSliceDate] = useState(0);
  const [sliceSerie, setSliceSerie] = useState(0);

  useEffect(() => {
    setLoading(true);
    let findId = props.location.pathname.split("/");
    findId = findId[findId.length - 1];
    axios
      .get("/channel/" + findId)
      .then((response) => {
        setTitle(response.data.name)
        setDates(response.data.epgs);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [props.page.pageInfo]);

  let dateList = [];
  let serieList = [];

  const clicked = (id,day) => {
    console.log(day)
    if (hoveredEpisode) {
      props.history.push(props.history.location.pathname + '/watch-' + day + "_" + id)
    }
  };

  // const goBack = () => {
  //   console.log(props.page.pageInfo)
  //   props.chooseCategory(props.page.pageInfo.back.title,props.page.pageInfo.back.link,props.page.pageInfo.back)
  // }

  useEffect(() => {
    const moverChange = (e) => {
      const columnSeason = dateList.length > 6 ? 6 : dateList.length;
      const columnEpisodes = serieList.length > 6 ? 6 : serieList.length;

      switch (e.key) {
        case "ArrowDown":
          if (moverSerie === 0) {
            if (columnSeason > moverDate) {
              setMoverDate(moverDate + 1);
            } else if (
              columnSeason === moverDate &&
              sliceDate + 6 < dateList.length
            ) {
              setSliceDate(sliceDate + 1);
            }
            setSliceSerie(0);
          } else {
            if (columnEpisodes > moverSerie) {
              setMoverSerie(moverSerie + 1);
            } else if (
              columnEpisodes === moverSerie &&
              sliceSerie + 6 < serieList.length
            ) {
              setSliceSerie(sliceSerie + 1);
            }
          }
          break;
        case "ArrowUp":
          if (moverSerie === 0) {
            if (moverDate > 1) {
              setMoverDate(moverDate - 1);
            } else if (sliceDate > 0) {
              setSliceDate(sliceDate - 1);
            }
            setSliceSerie(0);
          } else {
            if (moverSerie > 1) {
              setMoverSerie(moverSerie - 1);
            } else if (sliceSerie > 0) {
              setSliceSerie(sliceSerie - 1);
            }
          }
          break;
        case "ArrowRight":
          if (moverSerie === 0 && serieList.length > 0) {
            setMoverSerie(1);
          }
          break;
        case "ArrowLeft":
          if (moverSerie > 0) {
            setMoverSerie(0);
            setHoveredSerie(null)
          }
          break;
        case "Enter":
          clicked();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", moverChange);
    return () => {
      document.removeEventListener("keydown", moverChange);
    };
  });

  useEffect(() => {
    const scrollDown = (e) => {
      if (e.deltaY > 0) {
        if (moverSerie === 0 && sliceDate + 6 < dateList.length) {
          setSliceDate((s) => s + 1);
        } else if (moverSerie > 0 && sliceSerie + 6 < serieList.length) {
          setSliceSerie((e) => e + 1);
        }
      } else if (e.deltaY < 0) {
        if (moverSerie === 0 && sliceDate > 0) {
          setSliceDate((s) => s - 1);
        } else if (moverSerie > 0 && sliceSerie > 0) {
          setSliceSerie((e) => e - 1);
        }
      }
    };

    window.addEventListener("wheel", scrollDown);
    return () => {
      window.removeEventListener("wheel", scrollDown);
    };
  }, [dateList, serieList, sliceDate, moverSerie, sliceSerie]);

  const hoverSet = (property, sezona) => {
    if (sezona) {
      setMoverDate(property);
      setMoverSerie(0);
      setSliceSerie(0);
    } else setMoverSerie(property);
  };

  const getLink = (src) => {
    setHoveredSerie(src);
  };

  let tvUnazadLista = null;

  if (dates) {
    for (let key in dates) {
      dateList.push(key);
    }

    let dateListShow = dateList
      .slice(sliceDate, sliceDate + 6)
      .map((date, index) => {
        return (
          <Sezone
            key={date}
            name={date}
            active={index === moverDate - 1}
            onClick={hoverSet.bind(this, index + 1, true)}
          />
        );
      });

    let serieListShow = null;

    if (dates[dateList[moverDate - 1]]) {
      serieListShow = dates[dateList[moverDate - 1]].map((serie, index) => {
        return serieList.push(serie);
      });

      serieListShow = serieList
        .slice(sliceSerie, sliceSerie + 6)
        .map((serie, index) => {
          return (
            <Sezone
              key={index}
              name={serie.name + " " + serie.time}
              active={index === moverSerie - 1}
              src={serie.src}
              onHover={hoverSet.bind(this, index + 1, false)}
              hovered={getLink}
              onClick={() => clicked(serie.id,serie.start.split(" ")[0])}
              serie
            />
          );
        });
    }

    tvUnazadLista = (
      <React.Fragment>
        <div className="TVUnazadKat">
          <h2>Datum</h2>
          {dateListShow}
        </div>
        <div className="TVUnazadKat">
          <h2>Emisije</h2>
          {serieListShow}
        </div>
      </React.Fragment>
    );
  }

  return (
    <PageLayout
      // title={props.page.pageInfo.title}
      // back={props.page.pageInfo.back}
      title={title}
    >
      <div className="ContainerTVUnazad">
        {loading ? <Loader /> : tvUnazadLista}
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = { changePage, savePages, chooseCategory };

export default connect(mapStateToProps, mapDispatchToProps)(TVUnazad);
