import React from 'react'
import './List.css'
import Heart from '../../assets/svgs/Heart.js'

const list = (props) => {
  const classCurr = ['ContainerList']

  if (props.active) {
    classCurr.push('active')
  }

  let icon = null

  if (props.icon) {
    icon = <img src={props.icon} alt="" />
  }

  const clickEvent = (e) => {
    console.log(e.target)
  }
 
  return (
    <div
      className={classCurr.join(' ')}
      onMouseOver={props.onHover}
      onClick={props.onClick}
      // onClick={clickEvent}
    >
      <div className={'List'}>
        {icon}
        <div className={props.icon ? 'withIcon' : 'withoutIcon'}>
          {props.text}
        </div>
        <p className="ListTime">{props.time ? props.time.join(' - ') : null}</p>
        {props.favorite !== undefined ? (
          <div onClick={(e) => {e.stopPropagation(); props.toggleFavorite()}} className={props.favorite ? "favorit active" : "favorit"}>
            <Heart />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default React.memo(list)
