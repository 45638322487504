import React, { useState, useEffect } from 'react'
import './Prikaz.css'

import PageLayout from '../../../hoc/PageLayout/PageLayout'
import axios from '../../../axios/axiosConfig'

import Play from '../../../components/Play/Play'
import Loader from '../../../components/UI/Loader/Loader'
import Sezone from '../../../components/Sezone/Sezone'

import {
  changePage,
  savePages,
  chooseCategory,
} from '../../../store/actions/page'
import { connect } from 'react-redux'

const Prikaz = (props) => {
  const [show, setShow] = useState(null)
  const [moverSeason, setMoverSeason] = useState(1)
  const [moverEpisode, setMoverEpisode] = useState(0)
  const [sliceEpisodes, setSliceEpisodes] = useState(0)
  const [sliceSeasons, setSliceSeasons] = useState(0)

  const [hoveredEpisode, setHoveredEpisode] = useState(null)

  const play = (episode) => {
    if(episode === false){
      props.history.push(props.history.location.pathname + '/watch')
    }else {
      props.history.push(props.history.location.pathname + '/watch-' + episode )
    }
  }

  // const clicked = () => {
  //   props.savePages([
  //     ...props.page.lastpages.slice(0, 4),
  //     { page: props.page.pageInfo.title, moverSeason , moverEpisode, sliceSeasons, sliceEpisodes },
  //   ]);
  //   props.chooseCategory(show.name, hoveredEpisode, props.page.pageInfo);
  //   props.changePage(6);
  // };

  // useEffect(() => {
  //   if(props.page.lastpages[4] && props.page.lastpages[4].page === props.page.pageInfo.title){
  //     setMoverSeason(props.page.lastpages[4].moverSeason)
  //     setMoverEpisode(props.page.lastpages[4].moverEpisode)
  //     setSliceSeasons(props.page.lastpages[4].sliceSeasons)
  //     setSliceEpisodes(props.page.lastpages[4].sliceEpisodes)
  //   }
  // },[])

  useEffect(() => {
    let findId = props.location.pathname.split('/')
    findId = findId[findId.length - 1]
    if (props.location.pathname.includes('/filmovi')) {
      axios
        .get('/movie/' + findId)
        .then((response) => {
          setShow(response.data)
        })
        .catch((error) => console.log(error))
    } else {
      axios
        .get('/serie/' + findId)
        .then((response) => {
          setShow(response.data)
        })
        .catch((error) => console.log(error))
    }
  }, [props.page.pageInfo])

  let seasons = []
  let episodes = []

  useEffect(() => {
    const moverChange = (e) => {
      if (show && props.history.location.pathname.includes('serije')) {
        const columnSeason = show.seasons.length > 6 ? 6 : show.seasons.length
        const columnEpisodes = episodes.length > 6 ? 6 : episodes.length

        switch (e.key) {
          case 'ArrowDown':
            if (moverEpisode === 0) {
              if (columnSeason > moverSeason) {
                setMoverSeason(moverSeason + 1)
              } else if (
                columnSeason === moverSeason &&
                sliceSeasons + 6 < seasons.length
              ) {
                setSliceSeasons(sliceSeasons + 1)
              }
              setSliceEpisodes(0)
            } else {
              if (columnEpisodes > moverEpisode) {
                setMoverEpisode(moverEpisode + 1)
              } else if (
                columnEpisodes === moverEpisode &&
                sliceEpisodes + 6 < episodes.length
              ) {
                setSliceEpisodes(sliceEpisodes + 1)
              }
            }
            break
          case 'ArrowUp':
            if (moverEpisode === 0) {
              if (moverSeason > 1) {
                setMoverSeason(moverSeason - 1)
              } else if (sliceSeasons > 0) {
                setSliceSeasons(sliceSeasons - 1)
              }
              setSliceEpisodes(0)
            } else {
              if (moverEpisode > 1) {
                setMoverEpisode(moverEpisode - 1)
              } else if (sliceEpisodes > 0) {
                setSliceEpisodes(sliceEpisodes - 1)
              }
            }
            break
          case 'ArrowRight':
            if (moverEpisode === 0 && episodes.length > 0) {
              setMoverEpisode(1)
            }
            break
          case 'ArrowLeft':
            if (moverEpisode > 0) {
              setMoverEpisode(0)
            }
            break
          case 'Enter':
            if (moverEpisode > 0) {
              // clicked();
            }
            break
          default:
            break
        }
      } else {
        switch (e.key) {
          case 'Enter':
            play()
            break
          default:
            break
        }
      }
    }

    document.addEventListener('keydown', moverChange)
    return () => {
      document.removeEventListener('keydown', moverChange)
    }
  })

  useEffect(() => {
    const scrollDown = (e) => {
      if (e.deltaY > 0) {
        if (moverEpisode === 0 && sliceSeasons + 6 < seasons.length) {
          setSliceSeasons((s) => s + 1)
        } else if (moverEpisode > 0 && sliceEpisodes + 6 < episodes.length) {
          setSliceEpisodes((e) => e + 1)
        }
      } else if (e.deltaY < 0) {
        if (moverEpisode === 0 && sliceSeasons > 0) {
          setSliceSeasons((s) => s - 1)
        } else if (moverEpisode > 0 && sliceEpisodes > 0) {
          setSliceEpisodes((e) => e - 1)
        }
      }
    }

    window.addEventListener('wheel', scrollDown)
    return () => {
      window.removeEventListener('wheel', scrollDown)
    }
  }, [seasons, episodes, sliceSeasons, moverEpisode, sliceEpisodes])

  const hoverSet = (property, sezona) => {
    if (sezona) {
      setMoverSeason(property)
      setMoverEpisode(0)
      // setSliceSeasons(0)
    } else setMoverEpisode(property)
  }

  const getLink = (src) => {
    setHoveredEpisode(src)
  }

  let serijeLista = <Play active onClick={() => play(false)} />

  if (show) {
    if (
      // props.page.pageInfo.back.title &&
      // props.page.pageInfo.back.title.includes("Serije")
      props.location.pathname.includes('/serije')
    ) {
      show.seasons.map((season) => {
        return seasons.push(season)
      })

      let seasonsShow = seasons
        .slice(sliceSeasons, sliceSeasons + 6)
        .map((season, index) => {
          return (
            <Sezone
              key={season.id}
              name={season.name}
              active={index === moverSeason - 1}
              onClick={hoverSet.bind(this, index + 1, true)}
            />
          )
        })

      show.episodes.forEach((episode) => {
        if (seasons.length > 0) {
          if (
            parseInt(episode.properties.season) ===
            show.seasons[moverSeason - 1 + sliceSeasons].season_number
          ) {
            episodes.push(episode)
          }
        } else {
          seasonsShow = <Sezone key={0} name="Sezona" active />
          episodes.push(episode)
        }
      })

      let episodesShow = episodes
        .slice(sliceEpisodes, sliceEpisodes + 6)
        .map((episode, index) => {
          return (
            <Sezone
              key={episode.id}
              name={episode.name}
              active={index === moverEpisode - 1}
              onHover={hoverSet.bind(this, index + 1, false)}
              src={episode.src}
              hovered={getLink}
              onClick={() => play(episode.id)}
              epizoda
            />
          )
        })

      serijeLista = (
        <div className="SerijeLista">
          <div>
            <h2>Sezone</h2>
            {seasonsShow}
          </div>
          <div>
            <h2>Epizode</h2>
            {episodesShow}
          </div>
        </div>
      )
    }
  }

  let prikaz = null
  if (show) {
    prikaz = (
      <React.Fragment>
        <div className="PrikazSlike">
          <img src={show.stream_icon} alt={show.name} />
        </div>
        <div className="PrikazInformacije">
          <p>
            <b>Opis:</b> {show.properites ? show.properites.plot : show.plot}
          </p>
          <p>
            <b>Zanr: </b> {show.properites ? show.properites.genre : show.genre}
          </p>
          {show.properites ? (
            <p>
              <b>Ocena: </b> {show.properites.rating}
            </p>
          ) : null}

          <p>
            <b>Datum izdavanja:</b>{' '}
            {show.properites ? show.properites.releasedate : show.release_date}
          </p>
        </div>
        <div className="Kontrole">{serijeLista}</div>
      </React.Fragment>
    )
  }

  return (
    <PageLayout
      // title={props.page.pageInfo.title}
      // back={props.page.pageInfo.back}
      title={show && show.name}
    >
      <div className="ContainerPrikaz">{show ? prikaz : <Loader />}</div>
    </PageLayout>
  )
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = { changePage, savePages, chooseCategory }

export default connect(mapStateToProps, mapDispatchToProps)(Prikaz)
