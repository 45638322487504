import * as actionTypes from "../actions/actionsTypes.js";
import { updateObject } from "../utility";

const initialState = {
  page: 0,
  lastpages: [],
  pageInfo: {
    title:null,
    link:null,
    back:null
  }
  
};

const reducor = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_PAGE:
      return updateObject(state, { page: action.page });
    case actionTypes.SAVE_PAGE:
      return updateObject(state, { lastpages: action.pageList });
    case actionTypes.CATEGORY_PAGE:
      return updateObject(state, {pageInfo: {title:action.title,link:action.link,back:action.back}})
    default:
      return state;
  }
};

export default reducor;
