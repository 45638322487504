import axios from "axios";

const axiosConfig = axios.create({
  // baseURL: "http://64.227.19.12:20020/api/",
  baseURL: "http://213.239.210.66:25555/api/"
  // headers: {Authorization :localStorage.getItem("authorization")}
});

axiosConfig.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwtToken");
  config.headers.Authorization = token;

  return config;
});

export default axiosConfig;
