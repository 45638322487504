import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import './index.css';
import App from './App';

import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import { Provider } from "react-redux";
import pageReducor from './store/reducors/page'


Sentry.init({dsn: "https://73b3491b6aa845bbae0f482c367a24b9@o285270.ingest.sentry.io/5369786"});

const rootReducer = combineReducers({
  page:pageReducor
});

const logger = (store) => {
  return (next) => {
    return (action) => {
      const result = next(action);
      return result;
    };
  };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(logger, thunk))
);


ReactDOM.render(
    <Provider store={store}><App /></Provider>,
  document.getElementById('root')
);

