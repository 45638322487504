import React, {useRef} from "react";
import "./Input.css";

const Input = (props) => {
  const inputRef = useRef(null);
  const classCurr = ["Input"];

  if (props.active) {
    classCurr.push("active");
  }

  const focus = () => {
    inputRef.current.focus()
  }

  return (
    <input
      ref={inputRef}
      className={classCurr.join(" ")}
      type={props.type}
      placeholder={props.placeholder}
      onChange={(e) => props.onChange(e.target.value)}
      onMouseOver={props.onMouseOver}
      onClick={focus}
    />
  );
};

export default React.memo(Input);
