import React from "react";
import "../Choice.css";

const nalogOption = (props) => {
  const classCurr = ["ContainerChoice"];

  if (props.active) {
    classCurr.push(["active"]);
  }

return(
  <div className={classCurr.join(' ')} onMouseOver={props.onHover}>
    <div className={"Choice"}>
      {props.text.map((p,i) => <p key={i}>{p}</p>)}
    </div>
  </div>
)
}

export default React.memo(nalogOption);
