
import React, { useEffect } from "react";
import "./PageLayout.css";

import Logo from "../../assets/images/logo.png";
// import Back from "../../components/UI/Back/Back";

import { withRouter } from "react-router-dom";

const PageLayout = (props) => {
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) {
      props.history.push("/login");
    }
  });

  return (
    <div className={"PageLayout"}>
      <img className={"PageLogo"} src={Logo} alt="logo" />
      <h1>{props.title}</h1>
      {props.children}
      {/* <Back link={props.back} mover={props.mover}/> */}
    </div>
  );
};

export default withRouter(PageLayout);
