import React from "react";
import "./Option.css";

const option = (props) => {
  const classCurr = ["ContainerOption"];

  if (props.active) {
    classCurr.push("active");
    props.hovered(() => props.id);
  }

  return (
    <div
      className={classCurr.join(" ")}
      to={props.link}
      onMouseOver={props.onHover}
      onClick={props.onClick}
    >
      <div className={"Option"}>
        <p>{props.text}</p>
      </div>
    </div>
  );
};

export default React.memo(option) ;
