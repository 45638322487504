import React from "react";
import "./Button.css";

const button = (props) => {

    const classCurr = ["ContainerButton"]

    if(props.active){
        classCurr.push("active")
    }

  return (
    <div className={classCurr.join(' ')}>
      <button className={"Button"} onClick={props.click} onMouseOver={props.onMouseOver}>
        {props.text}
      </button>
    </div>
  );
};

export default React.memo(button);
