  
import * as actionTypes from "./actionsTypes"

export const changePage = (page) => {
    return {
        type:actionTypes.CHANGE_PAGE,
        page
    }
}

export const savePages = (pageList) => {
    return {
        type:actionTypes.SAVE_PAGE,
        pageList
    }
}

export const chooseCategory = (title,link,back) => {
    return {
        type:actionTypes.CATEGORY_PAGE,
        title,
        link,
        back
    }
}